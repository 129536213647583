const lengthPass = (value) => /^.{8,32}$/.test(value)
const fundPwd = (value) => /^\d{6}$/.test(value)
const hasNum = (value) => /\d+/.test(value)
const hasLcLetter = (value) => /[a-z]/.test(value)
const hasPuLetter = (value) => /[A-Z]/.test(value)
const hasLetters = (value) => /[a-zA-Z]/.test(value)
const hasSpecial = (value) =>
  /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/.test(value)

const verifyPass = (v) => {
  return [lengthPass(v), hasNum(v), hasLetters(v)].every(e => e)
}

export default { lengthPass, fundPwd, hasNum, hasLetters, hasLcLetter, hasPuLetter, hasSpecial, verifyPass }
