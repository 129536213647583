/*
 * @Author: ken yang
 * @Date: 2023-09-11 10:22:48
 * @LastEditTime: 2023-09-11 10:58:16
 * @LastEditors: ken yang
 * @Description: F合约路由
 * @FilePath: /uexch_pc/src/view/futures/index.js
 */

import AppLayout from '@/layout/AppLayout.vue'

const FFutures = () => import('@/subProject/ffutures/view/ffutures/FFutures.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/ffutures',
        props: route => {
          const symbol = route.query.symbol || 'BTCUSDT'
          if (!route.query.symbol) {
            replaceParamVal('symbol', symbol)
          }
          return { symbol }
        },
        component: FFutures,
        meta: { key: 'ffutures' },
      },
      // {
      //   path: '/futures-info',
      //   component: FuturesInfo,
      //   meta: { key: 'FuturesInfo' },
      // }
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
