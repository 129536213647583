const debounce = (func, delay) => {
  // 定义一个变量timer，用于存储定时器的返回值
  let timer = null
  // 返回一个新的函数，这个函数会在延迟时间后执行func
  return (...args) => {
    // 如果已经存在一个定时器，那么取消它
    if (timer) {
      clearTimeout(timer)
    }
    // 创建一个新的定时器，延迟执行func
    timer = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

export default debounce
