import AppLayout from '@/layout/AppLayout.vue'

const Content = () => import('@/view/help/Content.vue')
const AboutUs = () => import('@/view/help/AboutUs.vue')
const Fees = () => import('@/view/help/Fees.vue')
const Bulletin = () => import('@/view/help/Bulletin.vue')
const UserMessage = () => import('@/view/help/UserMessage.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/help/content',
        props: route => ({ type: route.query.type }),
        component: Content,
      },
      {
        path: '/help/aboutUs',
        component: AboutUs,
      },
      {
        path: '/help/fees',
        props: route => {
          const tabValue = route.query.tabValue
          const levelsTabValue = route.query.levelsTabValue

          return { tabValue, levelsTabValue }
        },
        component: Fees,
      },
      {
        path: '/help/bulletin',
        component: Bulletin,
      },
      {
        path: '/help/userMessage',
        component: UserMessage,
      },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
