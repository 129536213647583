<!--
 * @Author: ken yang
 * @Date: 2022-09-02 12:46:05
 * @LastEditTime: 2023-04-03 16:12:48
 * @LastEditors: ken yang
 * @Description: 复制按钮
 * @FilePath: /uexch_pc/src/components/buttons/CopyToClip.vue
-->
<template>
  <Row class="CopyToClip transition" @click="onCopyToClip">
    <slot name="front" />
    <IconSvg name="icon-IconCopy" :style="{'font-size': size + 'px', 'flex': `0 0 ${size}px`}" />
    <slot name="back" />

  </Row>
</template>

<script setup>
import Row from '@/layout/flex/Row.vue'
const route = useRoute()

const props = defineProps({
  label: {
    default: ''
  },
  value: {
    default: ''
  },
  size: { default: 12 },
  type: { default: '' }
})

const onCopyToClip = () => {
  copyToClip(props.value)
  ElMessage.success($t('alert.copied'))
}

</script>

<style lang="scss">
@import '@/sass/var/index.scss';
.CopyToClip {
  cursor: pointer;
  color: $color-info;
  word-break: break-word;

  &:hover{
    color: $color-primary;
  }
}
</style>

