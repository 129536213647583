/*
 * @Author: ken yang
 * @Date: 2022-09-03 11:18:27
 * @LastEditTime: 2023-02-28 16:57:01
 * @LastEditors: ken yang
 * @Description: c2c 路由文件
 * @FilePath: /uexch_pc/src/view/ieo/index.js
 */
import AppLayout from "@/layout/AppLayout.vue";

// import Quotes from '@/view/quotes/Quotes.vue'
const airdrop = () => import("@/view/airdrop/airdrop.vue");
const airdropDel = () => import("@/view/airdrop/airdropDel.vue");
const airdropInfo = () => import("@/view/airdrop/airdropInfo.vue");

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: "/airdrop",
        component: airdrop,
        meta: { key: "airdrop" },
      },
      {
        path: "/airdropDel",
        component: airdropDel,
        meta: { key: "airdropDel" },
      },
      {
        path: "/airdropInfo",
        component: airdropInfo,
        meta: { key: "airdropInfo" },
      },
    ],
  },
];
const components = {};

export default {
  routes,
  components,
};
