/*
 * @Author: ken yang
 * @Date: 2023-04-24 14:59:11
 * @LastEditTime: 2023-04-26 10:31:08
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/hook/table.js
 */

const usePagination = (getData, page) => {
  const pages = reactive({
    ...page ?? PAGES,
  })

  const confirmAlert = async (o = {}) => {
    const { title = $t('alert.title.del'), type = 'warning' } = o
    return ElMessageBox.confirm(title, $t('alert.hint'), {
      confirmButtonText: $t('alert.confirm'),
      cancelButtonText: $t('alert.cancel'),
      type: type,
      confirmButtonClass: 'h-36',
      cancelButtonClass: 'h-36 m-r-10',
    })
  }

  watch(
    () => pages.pageNum,
    () => {
      getData()
    }
  )
  watch(
    () => pages.pageSize,
    () => {
      getData()
    }
  )
  return { pages, confirmAlert }
}

export { usePagination }
