/*
 * @Author: ken yang
 * @Date: 2022-09-28 20:13:54
 * @LastEditTime: 2023-05-31 18:03:50
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/home/index.js
 */
import AppLayout from '@/layout/AppLayout.vue'
// import Home from '@/view/home/Home.vue'
const Home = () => import('@/view/home/Home.vue')
const AuthLoginRedir = () => import('@/view/home/AuthLoginRedir.vue')
const Upgrade = () => import('@/view/home/Upgrade.vue')
const Bft = () => import('@/view/home/Bft.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/home',
        component: Home,
        meta: { key: 'home' },
      },
      {
        path: '/bft',
        component: Bft,
        meta: { key: 'bft' },
      },

      {
        path: '/authLoginRedir',
        component: AuthLoginRedir,
        meta: { key: 'AuthLoginRedir' },
      },
    ],
  },
  {
    path: '/upgrade',
    component: Upgrade,
  },
]
const components = {}

export default {
  routes,
  components,
}
