
export default {
  mounted (el, ops) {
    el.addEventListener('click', (e) => {
      e.stopPropagation()
      const router = ops.value
      window.open(router, '_blank')
    })
  }
}
