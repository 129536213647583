/*
 * @Author: ken yang
 * @Date: 2022-08-30 11:14:14
 * @LastEditTime: 2023-06-14 15:57:20
 * @LastEditors: ken yang
 * @Description: F合约store
 * @FilePath: /uexch_pc/src/store/c2c.js
 */
import { defineStore } from 'pinia'
import { mapArrayToGetBarsData } from '@/subProject/ffutures/utils/ffutures.js/filter.js'

const useFfuturesStore = defineStore('ffuturesStore', {
  state: () => {
    return {
      symbol: 123,
      __lastPrice: '' /** 最新价原始数据，不做渲染，值接受原始数据 */
    }
  },
  getters: {

  },
  actions: {
    async getKline () {
      const matchKeys = ['p', 'time', 'ct', 'open', 'close', 'high', 'low', 'volume', 'c', 'pc', 'pcp']
      /** 图表需要的字段集合 */
      const tvFields = ['open', 'high', 'low', 'volume', 'time', 'close']

      const parmas = {
        tradeType: 'linearPerpetual',
        symbol: 'BTCUSDT',
        period: '15m'
      }
      const res = await ffuturesApi.kline(parmas)
      const formatKline = mapArrayToGetBarsData(res.data, matchKeys, tvFields)
      console.log('111', formatKline)
      this.__lastPrice = formatKline[0]?.close
    }
  },
  init () {
    // FfuturesSocket.autoConnect()
  },
})

export default useFfuturesStore
