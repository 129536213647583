import axios from '@/subProject/ffutures/utils/request/index.js'

const baseUrl = 'https://test01.bitwexc.vip'

// 交易对查询
const instrumentsUrl = `${baseUrl}/v1/public/web/instruments`
const instruments = async (params) => {
  const data = await axios.get(instrumentsUrl, { params })
  return data
}

/** 合约k线 */
const klineUrl = `${baseUrl}/v1/market/kline`
const kline = async (params) => {
  const { data } = await axios.get(klineUrl, { params })
  return data
}

const needLoginUrl = []

export default {
  needLoginUrl,
  instruments,
  kline
}
