<template>
  <el-dialog
    class="dialog-d"
    :modelValue="details.show"
    modal-class="dialog-m-center"
    width="500px"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="false"
    :append-to-body="true"
    @close="onClose"
  >
    <template #header>
      <DiaHeader @close="onClose">
        <template #title>
          <span class="font-24 bold">{{ $t('user.ipRestrictTitle') }}</span>
        </template>
      </DiaHeader>
    </template>
    <Column class="w-100b m-b-6" y="c">
      <Row class="font-18 lh-28">
        <i18n path="user.ipRestrictBody">
          <template #fileName>
            <span v-openWin="'/help/content?type=disclaimers'" class="c-primary pointer">{{ $t('user.fileName') }}</span>
          </template>
        </i18n>
      </Row>
      <BiCheckBox class="m-t-20" :modelValue="type" @change="onChangeType">
        <Row class="font-14 c-info m-l-3">{{ $t('user.silence7days') }}</Row>
      </BiCheckBox>
    </Column>
    <Row class="w-100b" x="e">
      <el-button class="w-100b h-52 title-sm" type="primary" :loading="loading" @click="onSubmit">{{ $t('alert.confirm') }}</el-button>
    </Row>
  </el-dialog>
</template>
<script setup>
const emit = defineEmits()

const loading = $ref(false)
let type = $ref(false)
const details = $ref({
  show: false
})
const onClose = () => {
  details.show = false
  emit('end')
}
const onChangeType = (v) => {
  type = v
}
const onSubmit = () => {
  if (type)setLocalStorageWithExpiration('ipRestrictTime', '1', 7)
  details.show = false
}

const getData = async () => {
  let res
  try {
    res = await userApi.getIPLimit()
  } catch (error) {
    console.log('isRestrict', error)
  }
  return res
}

onBeforeMount(async () => {
  const isRestrict = await getData()
  if (!isRestrict) {
    emit('end')
    return
  }
  const value = getLocalStorageWithExpiration('ipRestrictTime')
  if (value === null) {
    details.show = true
  }
})

</script>
<style lang="scss" scoped>
</style>
