/*
 * @Author: ken yang
 * @Date: 2023-09-11 10:22:48
 * @LastEditTime: 2023-09-11 10:58:16
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/futures/index.js
 */

import AppLayout from '@/layout/AppLayout.vue'

const Futures = () => import('@/view/futures/Futures.vue')
// const GridLayout = () => import('@/view/futures/GridLayout.vue')
const FuturesInfo = () => import('@/view/futures/pages/FuturesInfo.vue')
// const Futures = () => import('@/view/futures/Futures.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/futures',
        props: route => {
          const symbol = route.query.symbol || 'BTCUSDT'
          if (!route.query.symbol) {
            replaceParamVal('symbol', symbol)
          }
          return { symbol }
        },
        component: Futures,
        meta: { key: 'futures' },
      },
      {
        path: '/futures-info',
        component: FuturesInfo,
        meta: { key: 'FuturesInfo' },
      }
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
