/*
 * @Author: ken yang
 * @Date: 2022-09-09 20:19:55
 * @LastEditTime: 2023-01-19 15:31:49
 * @LastEditors: ken yang
 * @Description: 个人中心的api
 * @FilePath: /uexch_pc/src/api/userCenter.js
 */
import axios from '@/utils/request'

const transferListUrl = '/account/query/transfer'
const transferList = async (body) => {
  const { data } = await axios.post(transferListUrl, body)
  return data.data
}

const loginPwdUpdateUrl = '/user/edit/password'
const loginPwdUpdate = async (body) => {
  const { data } = await axios.post(loginPwdUpdateUrl, body)
  return data.data
}

const fundsPwdUpdateUrl = '/user/update/funds-password'
const fundsPwdUpdate = async (body) => {
  const { data } = await axios.post(fundsPwdUpdateUrl, body)
  return data.data
}

const userAuthenticationInfoUrl = '/user/get/real-name-authentication'
const userAuthenticationInfo = async (body) => {
  const { data } = await axios.post(userAuthenticationInfoUrl, body)
  return data.data
}

const phoneOrEmailUpdateUrl = '/user/update/phone-or-email'
const phoneOrEmailUpdate = async (body) => {
  const { data } = await axios.post(phoneOrEmailUpdateUrl, body)
  return data.data
}

const googleUpdateUrl = '/user/google/update'
const googleUpdate = async (body) => {
  const { data } = await axios.post(googleUpdateUrl, body)
  return data.data
}

const getGoogleSecretKeyUrl = '/user/google/getNewOne'
const getGoogleSecretKey = async (body) => {
  const { data } = await axios.post(getGoogleSecretKeyUrl, body)
  return data.data
}

const querySumUrl = '/account/query/sum'
const querySum = async (body) => {
  const { data } = await axios.post(querySumUrl, body || {})
  return data.data
}

const depositCoinUrl = '/account/query/deposit/coin'
const depositCoin = async (body) => {
  const { data } = await axios.post(depositCoinUrl, body || {})
  return data.data
}

const withdrawCoinUrl = '/account/query/withdraw/coin'
const withdrawCoin = async (body) => {
  const { data } = await axios.post(withdrawCoinUrl, body || {})
  return data.data
}

const googleVerifyUrl = '/user/google/verify'
const googleVerify = async (body) => {
  const { data } = await axios.post(googleVerifyUrl, body || {})
  return data
}

const withdrawUrl = '/account/withdraw'
const withdraw = async (body) => {
  const { data } = await axios.post(withdrawUrl, body || {})
  return data
}

const quickExchangeListUrl = '/activity/quickExchange/list'
const quickExchangeList = async (body) => {
  const { data } = await axios.post(quickExchangeListUrl, body || {})
  return data.data
}

const exchangeUrl = '/activity/exchange'
const exchange = async (body) => {
  const data = await axios.post(exchangeUrl, body || {})
  return data
}

const needLoginUrl = []

export default {
  needLoginUrl,
  transferList,
  loginPwdUpdate,
  fundsPwdUpdate,
  userAuthenticationInfo,
  phoneOrEmailUpdate,
  googleUpdate,
  getGoogleSecretKey,
  querySum,
  depositCoin,
  withdrawCoin,
  googleVerify,
  quickExchangeList,
  withdraw,
  exchange
}
