<!--
 * @Author: ken yang
 * @Date: 2022-10-31 19:17:43
 * @LastEditTime: 2023-03-09 09:39:13
 * @LastEditors: ken yang
 * @Description: i18n插槽
 * @FilePath: /uexch_pc/src/components/widget/i18n.vue
-->
<template>
  <div>
    <span v-for="(item,index) in arr" :key="index">
      <!-- <span name="share" /> -->
      <slot v-if="item.key" :name="item.key" />
      <slot v-else>{{ item }}</slot>
    </span>
    <!-- <slot name="share" /> -->
  </div>
</template>
<script setup>

const props = defineProps({
  path: {}
})

const arr = $computed(() => {
  const arr = $ref([])
  const lang = $t.curLang()
  const curSentence = props.path.split('.').reduce((o, i) => {
    if (o) return o[i]
  }, lang)
  const reg = /\{.*?\}/g

  const sentences = curSentence.split(reg)
  const keys = curSentence.match(reg)

  sentences.forEach((element, index) => {
    arr.push(element)
    if (keys[index])arr.push({ key: keys[index].replace(/{|}/g, '') })
  })
  return arr
})

</script>
<style lang="scss">
</style>
