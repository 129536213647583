import AppLayout from '@/layout/AppLayout.vue'

// import Quotes from '@/view/quotes/Quotes.vue'
const Quotes = () => import('@/view/quotes/Quotes.vue')
const Odds = () => import('@/view/quotes/Odds.vue')
const Seniority = () => import('@/view/quotes/Seniority.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/quotes',
        component: Quotes,
        meta: { key: 'quotes' },
      },
      {
        path: '/quotes/market',
        component: Quotes,
        meta: { key: 'quotes' },
      },
      {
        path: '/quotes/odds',
        component: Odds,
        meta: { key: 'quotes' },
      },
      {
        path: '/quotes/seniority',
        component: Seniority,
        meta: { key: 'quotes' },
      },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
