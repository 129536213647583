/*
 * @Author: ken yang
 * @Date: 2022-11-04 17:25:24
 * @LastEditTime: 2023-08-02 10:41:03
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/globalFunc/downloadImage.js
 */
const downloadImage = (imgSrc, name) => {
  const image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = () => {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.download = name || 'photo'
      a.href = url
      a.click()
      a.remove()
      URL.revokeObjectURL(url)
    })
  }
  image.src = imgSrc
}
export default downloadImage
