/*
 * @Author: ken yang
 * @Date: 2023-04-23 11:43:04
 * @LastEditTime: 2023-08-25 17:44:38
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/store/quotes.js
 */
const subSocketTicker = (cb) => {
  const ws = Socket.autoConnect();
  const wsEventName = WS_KEYS.ticker;
  const quotesEventName = WS_KEYS.ticker + "@quotes";
  if (ws.hasEvent(quotesEventName)) {
    return;
  }

  const sendData = {
    id: randomString(),
    cmd: "sub",
    topic: wsEventName,
    data: {},
  };

  ws.on(quotesEventName, (data) => {
    cb(data);
  });
  ws.send(sendData);
};

const subContractSocketTicker = (cb) => {
  //const ws = FuturesSocket.autoConnect()
  // const wsEventName = WS_CONTRACT_KEYS.ticker;
  // const quotesEventName = WS_CONTRACT_KEYS.ticker + "@quotes";
  // if (ws.hasEvent(quotesEventName)) {
  //   return;
  // }

  // const sendData = {
  //   id: randomString(),
  //   cmd: "sub",
  //   topic: wsEventName,
  //   data: {},
  // };

  // ws.on(quotesEventName, (data) => {
  //   cb(data);
  // });
  // ws.send(sendData);
};

const updateTickersCb = {};
const updateContractTickersCb = {};

const useQuotesStore = defineStore("quotesStore", {
  state: () => {
    return {
      tickers: [], // 币币行情
      symbolsAll: [],
      coins: [],
      contractTickers: [], // 合约行情
      contractSymbolsAll: [], // 交易对
      contractCoins: [],
      optionals: [], // 现货的自选
      contractOptionals: [], // 合约的自选
    };
  },
  getters: {
    coinImageMap: (state) => {
      return state.coins.reduce(
        (o, e) => ({ ...o, [e.coinName]: e.iconUrl }),
        {}
      );
    },
    symbolsAllMap: (state) => {
      return state.symbolsAll.reduce(
        (o, e) => ({ ...o, [e.coinMarket]: e }),
        {}
      );
    },
    contractSymbolsAllMap: (state) => {
      return state.contractSymbolsAll.reduce(
        (o, e) => ({ ...o, [e.coinMarket]: e }),
        {}
      );
    },
    hasSymbolsAll: (state) => {
      return state.symbolsAll.length > 0;
    },
    hasContractSymbolsAll: (state) => {
      return state.contractSymbolsAll.length > 0;
    },
  },
  actions: {
    async getTickers() {
      // 现货行情接口调用
      const params = {};
      const tickers = await quotesApi.tickers(params);
      const tickerTops = await quotesApi.tickersOnTop({});
      const tickerTopCoinMarkets = tickerTops.map((e) => e.coinMarket);

      for (let i = 0; i < tickers.length; i++) {
        const e = tickers[i];

        const cb = (data, newData) => {
          data.lastPrice = +newData.lastPrice;
          data.rangeAbility = newData.rangeAbility;
          data.highest = +newData.highest;
          data.lowest = +newData.lowest;
          data.amount = +newData.amount;
          data.turnover = +newData.turnover;
        };
        updateTickersCb[e.coinMarket] = cb;
        updateTickersCb[e.coinMarket](tickers[i], tickers[i]);
      }
      this.tickers = tickers.map((e) => ({
        ...e,
        isHot: tickerTopCoinMarkets.includes(e.coinMarket),
      }));
    },
    async getContractTickers() {
      // 合约行情接口调用
      // 报错注释
      // const params = { coinMarketType: 'CONTRACT' }
      // const contractTickers = await quotesApi.contractTickers(params)
      // for (let i = 0; i < contractTickers.length; i++) {
      //   const e = contractTickers[i]
      //   const cb = (data, newData) => {
      //     data.lastPrice = +newData.lastPrice
      //     data.rangeAbility = newData.rangeAbility
      //     data.highest = +newData.highest
      //     data.lowest = +newData.lowest
      //     data.amount = +newData.amount
      //     data.turnover = +newData.turnover
      //   }
      //   updateContractTickersCb[e.coinMarket] = cb
      //   updateContractTickersCb[e.coinMarket](contractTickers[i], contractTickers[i])
      // }
      // this.contractTickers = contractTickers.map(e => ({
      //   coinId: e.coinId,
      //   lastPrice: e.lastPrice,
      //   rangeAbility: e.rangeAbility,
      //   highest: e.highest,
      //   lowest: e.lowest,
      //   amount: e.amount,
      //   turnover: e.turnover,
      //   coinMarket: e.coinMarket,
      //   lastPriceCny: e.lastPriceCny,
      //   amountPrecision: e.amountPrecision,
      //   pricePrecision: e.pricePrecision,
      // }))
    },
    async getSymbolsAll() {
      // 现货交易对接口调用
      const data = await quotesApi.coinMarkets({});
      this.$patch((state) => {
        state.symbolsAll = data;
      });
    },
    async getContractSymbolsAll() {
      // 合约交易对接口调用
      // const data = await quotesApi.contractCoinmarket({})
      // this.$patch(state => {
      //   state.contractSymbolsAll = data
      // })
    },
    async getCoins() {
      const data = await configApi.coinInfo({});
      this.$patch((state) => {
        state.coins = data;
      });
    },
    async getOptionals() {
      const data = await quotesApi.optionalList({});
      this.$patch((state) => {
        state.optionals = data;
      });
    },
    async getContractOptionalList() {
      // 报错注释
      // const data = await quotesApi.contractOptionalList({})
      // this.$patch(state => {
      //   state.contractOptionals = data
      // })
    },
    async subPrice() {
      const cb = debounce(({ data }) => {
        if (isEmpty(data)) {
          return;
        }
        const index = this.tickers.findIndex(
          (e) => e.coinMarket == data?.coinMarket
        );
        if (index != -1) {
          updateTickersCb[data?.coinMarket](this.tickers[index], data);
        }
      }, 50);
      subSocketTicker(cb);
    },
    async subContractPrice() {
      console.log("subContractPrice");
      const cb = debounce(({ data }) => {
        if (isEmpty(data)) {
          return;
        }
        const index = this.contractTickers.findIndex(
          (e) => e.coinMarket == data?.coinMarket
        );
        if (index != -1) {
          updateContractTickersCb[data?.coinMarket](
            this.contractTickers[index],
            data
          );
        }
      }, 50);
      subContractSocketTicker(cb);
    },
    runSocket() {
      this.subPrice();
      this.subContractPrice();
    },
  },
  async init() {
    this.getTickers();
    this.getSymbolsAll();
    this.runSocket();
    this.getCoins();
    await this.getContractSymbolsAll();
    this.getContractTickers();
    if (localStorage.token) {
      this.getOptionals();
      this.getContractOptionalList();
    }
  },
});

export default useQuotesStore;
