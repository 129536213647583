<!--
 * @Author: ken yang
 * @Date: 2023-02-01 14:17:09
 * @LastEditTime: 2023-02-01 15:04:53
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/userCenter/SecurityCenter/components/DiaChangeName.vue
-->
<template>
  <el-drawer
    class="DiaTradeStyleSet dialog-d w-480"
    :modelValue="modelValue"
    :destroy-on-close="true"
    append-to-body
    @close="onClose"
  >
    <template #header>
      <Column class="w-100b" x="s">
        <Row class="font-22 bold c-d" x="c">{{ $t("trade.preference") }}</Row>
      </Column>
    </template>

    <Column class="w-100b font-14">
      <!-- 隐藏分仓合仓 -->
      <!-- <Row class="m-b-18 label c-info">{{ $t('trade.tradeSet') }}</Row>
      <Row class="w-100b pointer" x="sb" @click="onChangePositionMode">
        <Row class="c-dark font-14">{{ $t('futures.positionType') }}</Row>
        <Row>
          <Row class="c-info m-r-3">{{ futuresStore.positionModeOption.find((obj)=>obj.key===futuresStore.positionMode)?.label }}</Row>
          <IconSvg name="icon-IconMore_Home" class="c-placeholder font-12" />
        </Row>
      </Row>
      <Row class="w-100b h-1 bg-placeholder-50 m-t-24" /> -->

      <Row class="m-b-18 label c-info m-t-18">{{ $t("trade.basic") }}</Row>
      <Row class="m-b-10 label">{{ $t("trade.kLineSettings") }}</Row>
      <el-radio-group :modelValue="configStore.tradeStyle" class="w-100b m-b-10 row f-x-sb f-wrap">
        <Row
          v-for="(s) in configStore.tradeStyleMap"
          :key="s"
          class="w-200 h-48 p-l-20 p-r-10 bg-line radius-4 pointer transition radio border"
          :class="[configStore.tradeStyle == s ? 'border-c-primary' : 'border-c-line']"
          y="c"
          x="sb"
          @click="setTradeStyle(s)"
        >
          <Row>
            <img v-if="s == 'trade-style-0'" class="w-21 h-18" src="@/image/trade/trade-style-0.webp">
            <img v-if="s == 'trade-style-1'" class="w-21 h-18" src="@/image/trade/trade-style-1.webp">
            <span class="lh-18 label w-80 m-l-8">{{ $t(`trade.kLineSettingsMap.${s}`) }}</span>
          </Row>
          <el-radio :label="s" size="large">{{ '' }}</el-radio>
        </Row>
      </el-radio-group>
    </Column>
  </el-drawer>
</template>
<script setup>
import PositionMode from '@/view/futures/components/dia/PositionMode.vue'
const futuresStore = useFuturesStore()
const route = useRoute()
const c2cStore = useC2cStore()
const userStore = useUserStore()
const emit = defineEmits()
const props = defineProps({
  modelValue: {
    default: false
  },
})
const configStore = useConfigStore()

const onClose = () => {
  emit('update:modelValue', false)
}

const onChangePositionMode = () => {
  DiaSingle({
    com: PositionMode,
    submited: async (v) => {
      emit('update:modelValue', false)
    }
  }
  )
}

const setTradeStyle = (s) => {
  configStore.setTradeStyle(s)
}

</script>
<style lang="scss">
.DiaTradeStyleSet{
  .el-radio__inner{
    width: 16px !important;
    height: 16px !important;
    &::after{
      width: 5px !important;
      height: 5px !important;
    }
  }
  .el-drawer__header{
    padding: 35px 30px !important;
    margin-bottom: 15px !important;
  }
  .el-drawer__body{
    padding: 0 30px !important;
  }
  .el-dialog__headerbtn{
    top: 0 !important;
    right: 0 !important;
  }
}
</style>
