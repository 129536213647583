const throttle = (cb, interval = 40) => {
  let startTime = Date.now()
  return (...args) => {
    const now = Date.now()
    if (now > startTime + interval) {
      startTime = Date.now()
      cb(...args)
    }
  }
}

export default throttle
