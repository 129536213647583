import { useCookies } from 'vue3-cookies'

const set = (...age) => {
  const { cookies } = useCookies()
  cookies.set(...age)
}

const get = (...age) => {
  const { cookies } = useCookies()
  let d = cookies.get(...age)
  if (d == 'undefined') {
    d = undefined
  }
  return d
}

export default {
  set,
  get
}
