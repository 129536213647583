/*
 * @Author: ken yang
 * @Date: 2022-09-28 20:13:54
 * @LastEditTime: 2023-08-02 15:58:17
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/user/index.js
 */
import AppLayout from '@/layout/AppLayout.vue'

// import Login from '@/view/user/Login.vue'
// import Register from '@/view/user/Register.vue'
// import ForgotPassword from '@/view/user/ForgotPassword.vue'

const Login = () => import('@/view/user/Login.vue')
const Register = () => import('@/view/user/Register.vue')
const RegisterUpLand = () => import('@/view/user/RegisterUpLand.vue')
const ForgotPassword = () => import('@/view/user/ForgotPassword.vue')
const SetNewPassword = () => import('@/view/user/SetNewPassword.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/login',
        component: Login,
      },
      {
        path: '/register',
        redirect: '/user/register',
      },
      {
        path: '/user/register',
        component: Register,
      },
      {
        path: '/registerUpLand',
        component: RegisterUpLand,
      },
      {
        path: '/user/register',
        redirect: '/register',
      },
      {
        path: '/forgotPassword',
        component: ForgotPassword,
      },
      {
        path: '/setNewPassword',
        props: route => ({ account: (route.query.account) }),
        component: SetNewPassword,
      },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
