<template>
  <Row
    class="Pagination "
    :class="styleType == 1 ? 'w-100b h-32 lh-32' : 'w-100b'"
    x="e"
    y="c"
  >
    <template v-if="styleType == 1">
      <Row class="label-lg m-r-18 h-32 lh-32" y="c">
        <span class="m-r-5">{{ $t('table.total') }}</span>
        <span>{{ total }}</span>
      </Row>
      <el-pagination
        :current-page="Number(pageNum)"
        :page-size="Number(pageSize)"
        :background="true"
        layout="prev, pager, next, jumper"
        :page-count="Math.ceil(total / Number(pageSize))"
        @current-change="currentChange"
      />
    </template>
    <template v-if="styleType == 3">
      <el-pagination
        class="styleType-3"
        background
        :current-page="Number(pageNum)"
        :page-size="Number(pageSize)"
        layout="prev, pager, next"
        :page-count="Math.ceil(total / Number(pageSize))"
        @current-change="currentChange"
      />
    </template>
    <template v-if="styleType == 2 && total > pageSize">
      <Row>
        <Row
          class="w-20 h-20 radius-2 border pointer b"
          :class="pageNum != 1 ? '' : 'disabled'"
          x="c"
          y="c"
          @click="sub(pageNum)"
        >
          <IconSvg class="back" name="icon-back" />
        </Row>
        <Row
          class="w-20 h-20 radius-2 border pointer b m-l-8"
          :class="pageNum * pageSize < total ? '' : 'disabled'"
          x="c"
          y="c"
          @click="add(pageNum)"
        >
          <IconSvg class="back rotateZ-180" name="icon-back" />
        </Row>
      </Row>
    </template>
  </Row>
</template>

<script setup>
const emit = defineEmits()
const props = defineProps({
  total: {
    default: 0
  },
  num: {},
  pageNum: {},
  pageSize: {},
  styleType: {
    default: 1
  },
})

const currentChange = pageNum => {
  emit('update:pageNum', pageNum)
}

const add = () => {
  if (props.pageNum * props.pageSize < props.total) {
    currentChange(Number(props.pageNum) + 1)
  }
}

const sub = () => {
  if (props.pageNum != 1) {
    currentChange(Number(props.pageNum) - 1)
  }
}

</script>

<style lang="scss">
@use "sass:math";
@use "sass:map";
@import '@/sass/var/index.scss';

.dark{
  .Pagination {
    .b{
      border-color: #1C1F2C !important;
      color:  $color-info;
    }
    .disabled{
      border-color: $color-placeholder ;
      color: rgba($color-info,0.5) ;
      cursor: not-allowed;
    }
  }
}
.light{
  .Pagination {
    .b{
      border-color: $color-placeholder ;
      color:  $color-info;
    }
    .disabled{
      border-color: $color-placeholder ;
      color: $color-placeholder ;
      cursor: not-allowed;
    }
  }
}

.Pagination {
  margin-top: 5px;
  color: var(--el-text-color-regular);
  font-size: 12px;
  .el-input{
    --el-input-height: 32px;
  }
  .el-pagination__jump{
    height: 32px;
    line-height: 32px;
  }
  .back{
    font-size: 10px;
  }
  .styleType-3{
    --el-pagination-button-color: #{$font-color};
  }

  .el-pagination{
    --el-pagination-button-bg-color: transparent;
    --el-disabled-bg-color: transparent;
    --el-pagination-font-size: 16px;
    .el-pager li.is-active{
      background-color: var(--el-pagination-button-bg-color);
      color: #{$color-primary} !important;
      font-weight: 400 !important;
    }
    .el-pager li,.btn-prev,.btn-next{
      transition: all 0.3s;
      border-radius: 4px;
      border: 1px solid #{$color-placeholder} !important;
      font-weight: 400 !important;
    }
    .el-pager li.is-active{
      border-color: #{$color-primary} !important;
    }
    .el-pager li.more{
      border:none !important;
      svg{
        color: $color-placeholder !important;
      }
    }
  }
  .el-pagination__jump{
    .el-input__wrapper{
      border-radius: 4px;
    }
  }
}
</style>
