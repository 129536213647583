/*
 * @Author: ken yang
 * @Date: 2022-09-03 11:18:27
 * @LastEditTime: 2023-02-28 16:57:01
 * @LastEditors: ken yang
 * @Description: c2c 路由文件
 * @FilePath: /uexch_pc/src/view/ieo/index.js
 */
import AppLayout from "@/layout/AppLayout.vue";

// import Quotes from '@/view/quotes/Quotes.vue'
const tokenList = () => import("@/view/tokenList/tokenList.vue");
const exist = () => import("@/view/tokenList/exist.vue");
const progress = () => import("@/view/tokenList/progress.vue")
const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: "/tokenList",
        component: tokenList,
        meta: { key: "tokenList" },
      },
      {
        path: "/tokenList/exist",
        component: exist,
        meta: { key: "exist" },
      },
      {
        path: "/tokenList/progress",
        component: progress,
        meta: { key: "progress" },
      }
    ],
  },
];
const components = {};

export default {
  routes,
  components,
};
