<template>
  <div :class="addClass" :style="style">
    <slot />
  </div>
</template>

<script setup>
const props = defineProps({
  direction: {
    default: 'row'
  },
  alignItems: {
    default: ''
  },
  justifyContent: {
    default: ''
  },
  width: {},
})

const isRow = $computed(() => props.direction == 'row')
const mainAxis = $computed(() => (isRow ? 'x' : 'y'))
const crossAxis = $computed(() => (isRow ? 'y' : 'x'))
const x = $computed(() => (isRow ? props.justifyContent : props.alignItems))
const y = $computed(() => (isRow ? props.alignItems : props.justifyContent))

const shortening = s => {
  const map = {
    sb: 'sb',
    c: 'center',
    s: 'start',
    e: 'end'
  }
  const v = map[s] || s
  return v
}

const addClass = $computed(() => {
  const sx = shortening(x)
  const sy = shortening(y)
  const cs = [isRow ? 'row' : 'column', `f-${mainAxis}-${sx}`, `f-${crossAxis}-${sy}`]
  return cs
})

const style = $computed(() => {
  let s = {}
  if (props.width) {
    s = {
      ...s,
      width: `${props.width}px`,
      flex: `0 0 ${props.width}`
    }
  }

  return s
})

onBeforeMount(() => {})
</script>

<style lang="scss">

</style>
