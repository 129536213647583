/*
 * @Author: ken yang
 * @Date: 2022-09-28 20:13:54
 * @LastEditTime: 2023-07-25 10:50:44
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/c2c.js
 */
import axios from '@/utils/request'

// 列表
const listUrl = '/user/msgUserRelate/page'
const list = async (body) => {
  const { data } = await axios.post(listUrl, body)
  return data.data
}

// update
const updateUrl = '/user/msgUserRelate/update'
const update = async (body) => {
  const { data } = await axios.post(updateUrl, body)
  return data.data
}

// 获取设置
const getSetInfoUrl = '/user/msgUserSwitch/info'
const getSetInfo = async (body = {}) => {
  const { data } = await axios.post(getSetInfoUrl, body)
  return data.data
}

// 修改设置
const setInfoEditUrl = '/user/msgUserSwitch/update'
const setInfoEdit = async (body) => {
  const { data } = await axios.post(setInfoEditUrl, body)
  return data.data
}

// 全部已读
const readAllUrl = '/user/msgUserRelate/readAll'
const readAll = async (body = {}) => {
  const { data } = await axios.post(readAllUrl, body)
  return data.data
}

// 全部已读
const getUnreadCountUrl = '/user/msgUserRelate/getUnreadCount'
const getUnreadCount = async (body = {}) => {
  const { data } = await axios.post(getUnreadCountUrl, body)
  return data.data
}

// click
const clickUrl = '/user/msgUserRelate/click'
const click = async (body = {}) => {
  const { data } = await axios.post(clickUrl, body)
  return data.data
}

const needLoginUrl = []

export default {
  list,
  update,
  getSetInfo,
  setInfoEdit,
  readAll,
  getUnreadCount,
  click,
  needLoginUrl,
}
