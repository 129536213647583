/*
 * @Author: ken yang
 * @Date: 2022-09-07 09:21:36
 * @LastEditTime: 2023-06-06 16:32:00
 * @LastEditors: ken yang
 * @Description: map的store
 * @FilePath: /uexch_pc/src/store/map.js
 */
import { defineStore } from 'pinia'
import { nextTick } from 'vue'

const useMapStore = defineStore('mapStore', {
  state: () => {
    return {
      _areaList: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
    }
  },
  getters: {
    areaList: (state) => {
      return state._areaList
    },
  },
  actions: {
    async baseAreaList (type) {
      const data = await userApi.areaList({ type })
      this.$patch(state => {
        state._areaList[type] = data.map(e => ({ label: e.nationalityName, value: e.phoneAreaCode, icon: e.icon }))
      })
    },
    async getAreaList () {
      this.baseAreaList(0) // 注册
      this.baseAreaList(1) // 实名
      this.baseAreaList(2) // 登录
    }
  },
  init () {
    this.getAreaList()
  },
})

export default useMapStore
