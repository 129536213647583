import axios from "@/utils/request";

// 空投列表
const airdropListUrl = "/activity/airdropActivity/list";
const airdropList = async (params) => {
  const { data } = await axios.post(airdropListUrl, params);
  return data.data;
};

// 用户申领
const userApplyUrl = "/activity/airdropActivity/userApply";
const userApply = async (params) => {
  const { data } = await axios.post(userApplyUrl, params);
  return data.data;
};

// 查询用户详情
const airdropUserDetailUrl = "/activity/airdropActivity/detail";
const airdropUserDetail = async (params) => {
  const { data } = await axios.post(airdropUserDetailUrl, params);
  return data.data;
};

// 查询用户申领记录

const applyHistoryUrl = "/activity/airdropActivity/applyHistory";
const applyHistory = async (params) => {
  const { data } = await axios.post(applyHistoryUrl, params);
  return data.data;
};
const needLoginUrl = [userApplyUrl, airdropUserDetailUrl, applyHistoryUrl];
export default {
  needLoginUrl,
  airdropList,
  userApply,
  airdropUserDetail,
  applyHistory,
};
