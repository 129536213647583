
<template>
  <div class="w-100b">
    <Row class="pointer" @click="tableInstance.download()">下载excel</Row>
    <!-- isSearchHeader 可选、默认true -->
    <!-- cusPages 可选、默认{pageNum: 1,pageSize: 10} -->
    <Bitable
      ref="tableInstance"
      v-model:getData="getData"
      v-model:records="records"
      :form="form"
      :config="config"
      :fields="fields"
      :isDraggable="true"
      :isSearchHeader="true"
      :isDownExcel="true"
      :cusPages="{pageNum: 1,pageSize: 20}"
      :requestApi="reqApi"
      :isPagination="false"
      :isSerial="true"
      @onStart="onStart"
      @onEnd="onEnd"
    >
      <!-- 自定义搜索表单（如果不定义就 传入fields和form） -->
      <!-- <template #searchHeader>
        <Row class="w-100b m-b-10" y="s">
          <el-button type="primary" class="m-r-10" @click="onAddActivity">创建活动</el-button>
          <DynamicForm
            :fields="fields"
            :hasReset="true"
            :fontSize="12"
            @change="(k,v) => form[k] = v"
            @submit="getData"
            @reset="getData"
          >
            <template #end>
              <DownloadExcel v-model:loading="downloadExcelLoading" :form="form" :apiFn="walletApi.otherRecord" :format="config" />
            </template>
          </DynamicForm>
        </Row>
      </template> -->

      <template #searchHeaderFront>
        <el-button type="primary" class="m-r-10 h-32" @click="onAddActivity">创建活动</el-button>
      </template>

      <!-- 表格列 -->
      <template #activityTitleHeader>
        <span>自定义表头</span>
      </template>
      <template #activityTitle="row">
        <span>{{ row.activityTitle }}</span>
      </template>

      <!-- 操作 -->
      <template #action="{row,table}">
        <TableAction :actions="getTableAction(row)" :getData="getData" />
        <div @click="toExtend(row,table)">打开</div>
      </template>

      <template #expand="{row}">
        <Row>{{ row }}</Row>
      </template>

    </Bitable>

    <!-- <el-dialog v-model="detailFormReadOnly.show" :destroy-on-close="true" append-to-body width="70%">
      <DetailFormReadOnly
        v-if="detailFormReadOnly.show"
        :id="detailFormReadOnly.id"
        :currencys="mapStore.currencyList"
        :activityType="detailFormReadOnly.activityType"
      />
    </el-dialog>
    <el-dialog
      v-model="diaActivityInfo.show"
      top="5vh"
      :width="800"
      :destroy-on-close="true"
      append-to-body
    >
      <activitySubmitForm
        v-if="diaActivityInfo.show"
        :id="diaActivityInfo.id"
        :currencys="mapStore.currencyList"
        :disabled="diaActivityInfo.disabled"
        @submited="onWCEditenOrAdd"
      />
    </el-dialog> -->
  </div>
</template>

<script setup>
// import { DetailFormReadOnly, activitySubmitForm } from '@/view/activity/components/index.js'
import TableAction from './TableAction.vue'

const mapStore = useMapStore()
let reqApi = $ref('')

//

const diaActivityInfo = reactive({
  show: false,
  id: null,
  disabled: false,
})
const detailFormReadOnly = reactive({
  show: false,
  id: null,
  activityType: null,
})

// ----------------------

const tableInstance = $ref(null)
// 表格列表数据
const records = reactive([])
// 下载excel弹窗
const downloadExcelLoading = $ref(false)
// 更新列表的方法
const getData = $ref()

// 表单配置
const form = reactive({
  startTime: setTimeRange('day', 70)[0],
  endTime: setTimeRange('day', 70)[1],
})

const fields = $computed(() => {
  return [
    { type: 'input', key: 'activityTitle', label: '活动标题', value: form.activityTitle, placeholder: '活动标题' },
  ]
})

const statusMap = [
  {
    label: '开启',
    value: 1,
  }, {
    label: '关闭',
    value: 0,
  }
]

// 表格配置
const config = [
  { label: '活动标题', prop: 'activityTitle' },
  { label: '活动开始时间', prop: 'startTime', value: (row) => row.startTime, sortable: 'custom' },
  { label: '活动结束时间', prop: 'startTime', type: 'time' },
  { label: '图片', prop: 'popUpImg', type: 'img' },
  { label: '活动链接', prop: 'link' },
  { label: '参加人数', prop: 'attendActNum' },
  { label: '领奖人数', prop: 'receiveNum' },
  { label: '开启状态', prop: 'enabled', options: statusMap, isDownLoad: false },
]

// 操作配置
const getTableAction = (row) => {
  return [
    {
      label: '详情',
      onClick: () => onInfo(row),
      type: 'primary'
    },
    {
      label: '删除',
      type: 'delete',
      body: { id: row.id },
      requestApi: activityApi.registerSendBTCDel,
    },
  ]
}

const onStart = (e) => {
}

const onEnd = (e) => {
}
const changeStatus = async () => {

}
// --------------------------
const onInfo = async (row) => {
  detailFormReadOnly.id = row.id
  detailFormReadOnly.show = true
  detailFormReadOnly.activityType = row.activityType
}

const onAddActivity = async () => {
  diaActivityInfo.id = ''
  diaActivityInfo.show = true
}

// 世界杯活动的添加和编辑的回调
const onWCEditenOrAdd = async () => {
  diaActivityInfo.show = false
}

const toExtend = (row, table) => {
  table.toggleRowExpansion(row)
}

watch(() => detailFormReadOnly.show, (show) => {
  if (show) {
    return
  } else {
    detailFormReadOnly.id = null
    detailFormReadOnly.activityType = null
  }
})
onBeforeMount(() => {
  reqApi = walletApi?.otherRecord
}
)

</script>

<style lang="scss">

</style>
