/*
 * @Author: ken yang
 * @Date: 2023-05-22 14:41:01
 * @LastEditTime: 2023-05-22 14:52:56
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/globalFunc/compare.js
 */
const gt = (num1, mun2) => {
  return +num1 > +mun2
}
const gte = (num1, mun2) => {
  return +num1 >= +mun2
}
const lt = (num1, mun2) => {
  return +num1 < +mun2
}
const lte = (num1, mun2) => {
  return +num1 <= +mun2
}

export {
  gt,
  gte,
  lt,
  lte,
}
