const ready = {}
const postChildTasks = {}
const postParent = (e, d) => {
  const o = {
    event: e,
    data: d,
  }
  const postMap = {
    h5: (d) => parent.postMessage(d, '*'),
    android: (d) => app.callNative(d),
    ios: (d) => app.callNative(d),
  }
  postMap[platform](JSON.stringify(o))
}

const postChild = (id, e, d) => {
  const el = document.getElementById(id)
  const o = {
    event: e,
    data: d,
  }
  const runTask = () => {
    el.contentWindow.postMessage(JSON.stringify(o), '*')
  }
  if (ready[id]) {
    runTask()
  } else {
    if (!postChildTasks[id]) {
      postChildTasks[id] = []
    }
    postChildTasks[id].push(runTask)
  }
}

const fchildLoadEvent = (data) => {
  ready[data] = true
  const tasks = postChildTasks[data] || []
  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i]
    task()
  }
  postChildTasks[data] = []
}

const initEvent = () => {
  eventBus.on('toRouter', toRouter)
  eventBus.on('updateToekn', (token) => {
    localStorage.token = token
  })
  eventBus.on('updateLang', (lang) => {
    const configStore = useConfigStore()
    configStore.setLanguage(lang)
  })
}

const emit = (event, d) => {
  const data = isJsonString(d) ? JSON.parse(d) : d
  eventBus.emit(event, data)
}

const init = (cb) => {
  const { winId } = getUrlQuery()
  initEvent()
  if (winId) {
    window.addEventListener('load', (d) => {
      postParent('fchildLoad', d.currentTarget.winId)
    }, false)
  }

  window.addEventListener(
    'message',
    ({ data: d }) => {
      d = isJsonString(d) ? JSON.parse(d) : d
      let event
      let data
      if (isObject(d)) {
        event = d.event
        data = d.data
      } else {
        event = String(d)
      }

      if (event == 'fchildLoad') {
        fchildLoadEvent(data)
      } else {
        eventBus.emit(event, data)
      }
    },
    false
  )
}

export default {
  postParent,
  postChild,
  init,
  emit,
}
