/*
 * @Author: ken yang
 * @Date: 2022-09-17 19:33:24
 * @LastEditTime: 2023-02-10 15:13:44
 * @LastEditors: ken yang
 * @Description: ,
 * @FilePath: /uexch_pc/src/api/index.js
 */
import userApi from "./user.js";
import mapApi from "./map.js";
import fileApi from "./file.js";
import orderApi from "./order.js";
import ieoApi from "./ieo.js";
import userCenterApi from "./userCenter.js";
import configApi from "./config.js";
import quotesApi from "./quotes.js";
import rebateApi from "./rebate.js";
import activityApi from "./activity.js";
import verifyCodeApi from "./verifyCode.js";
import walletApi from "./wallet.js";
import financialApi from "./financial.js";
import liveChatApi from "./liveChat.js";
import c2cApi from "./c2c.js";
import futuresApi from "./futures.js";
import ffuturesApi from "@/subProject/ffutures/api/ffutures.js";
import mailApi from "./mail.js";
import airdropApi from "./airdrop.js";
import tokenListApi from "./tokenList.js";

const delay = (date) =>
  new Promise((resolve, reject) => {
    // 延迟
    setTimeout(() => resolve(), date || 2000);
  });

const needLoginUrl = [
  ...userApi.needLoginUrl,
  ...mapApi.needLoginUrl,
  ...fileApi.needLoginUrl,
  ...orderApi.needLoginUrl,
  ...ieoApi.needLoginUrl,
  ...userCenterApi.needLoginUrl,
  ...quotesApi.needLoginUrl,
  ...rebateApi.needLoginUrl,
  ...activityApi.needLoginUrl,
  ...verifyCodeApi.needLoginUrl,
  ...walletApi.needLoginUrl,
  ...financialApi.needLoginUrl,
  ...liveChatApi.needLoginUrl,
  ...futuresApi.needLoginUrl,
  ...ffuturesApi.needLoginUrl,
  ...mailApi.needLoginUrl,
  ...airdropApi.needLoginUrl,
  ...tokenListApi.needLoginUrl,
];
export {
  userApi,
  mapApi,
  fileApi,
  orderApi,
  ieoApi,
  userCenterApi,
  configApi,
  quotesApi,
  rebateApi,
  activityApi,
  verifyCodeApi,
  walletApi,
  financialApi,
  liveChatApi,
  c2cApi,
  futuresApi,
  mailApi,
  ffuturesApi,
  needLoginUrl,
  delay,
  airdropApi,
  tokenListApi
};
