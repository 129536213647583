<!--
 * @Author: ken yang
 * @Date: 2023-03-17 13:36:51
 * @LastEditTime: 2023-05-08 10:34:27
 * @LastEditors: ken yang
 * @Description: 活动弹窗
 * @FilePath: /uexch_pc/src/view/common/DiaActivity.vue
-->
<template>
  <el-dialog
    v-model="isActivity"
    width="500"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    append-to-body
    class="activity-award adius-10"
    modal-class="dialog-m-center"
    :show-close="false"
    @closed="toClose"
  >
    <template #default>
      <Column v-loading="loading" x="e" class="w-500 h-360">
        <img src="@/image/activity/icon-close.webp" class="w-30 h-30 pointer da-close" @click="isActivity = false">
        <img
          class="w-500 h-360 radius-10 pointer block"
          :src="popUpUrl"
          alt="no picture"
          @load="onImgLoad"
          @click="onGet"
        >
        <!-- <Row v-show="imgLoad" class="w-410 h-68 m-r-25 da-deail" x="c" y="c">
          <span class="font-42 bold c-white">{{ curItem?.amount }}</span>
          <span class="w-14" />
          <span class="font-42 bold c-white">{{ curItem?.coinName }}</span>
        </Row> -->
      </Column>
    </template>
  </el-dialog>
</template>
<script setup>
const emit = defineEmits()
const userStore = useUserStore()
let loading = $ref(false)
const props = defineProps(
  {
    popup: {},
    detailLoading: {
      default: {},
    },
    close: {}
  }
)

let isActivity = $ref(false)
let imgLoad = $ref(false)

let popUpUrl = $ref('')
const checkPop = async () => {
  props.detailLoading.loading = true
  try {
    const data = await activityApi.popUpOrNot()
    if (!data.show)toClose()
    if (!props.popup) { // 首页已经弹窗过的不再弹窗
      if (localStorage.popUpId === data.popUpId + '_' + userStore?.info?.userId) {
        emit('end')
        return
      }
    }
    if (!data.show && props.popup) { // 活动已结束
      ElMessage.success($t('activity.rewardEnd'))
      return
    }
    isActivity = data.show
    popUpUrl = data.popUpUrl
    localStorage.popUpId = data.popUpId + '_' + userStore?.info?.userId
    if (!isActivity)emit('end')
  } catch (error) {
    emit('end')
  } finally {
    props.detailLoading.loading = false
  }
}

const toClose = async () => {
  props?.close && props?.close()
  emit('end')
}

const statusOptions = $computed(() => {
  return {
    0: $t('activity.rewardSuccess'),
    1: $t('activity.rewardLate'),
    2: $t('activity.rewardAlready'),
    3: $t('activity.rewardEnd'),
  }
})

const onGet = async () => {
  loading = true
  try {
    const body = {}
    // if (props.activityId) {
    //   body.activityId = props.activityId
    // }
    const res = await activityApi.newRegisterActivitySubmit(body)
    ElMessage.success(statusOptions[res.data.status])
    isActivity = false
  } catch (error) {

  } finally {
    loading = false
  }
}

const onImgLoad = async () => {
  imgLoad = true
}

onBeforeMount(async () => {
  if (localStorage.token) {
    checkPop()
  } else {
    emit('end')
  }
})

</script>
<style lang="scss">
.block{
  display: block;
}
.activity-award{
  background: #f7f9fc00;
  box-shadow: 0px 2px 40px 0px rgba(0,0,0,0.13);
  border-radius: 0px;
  .el-dialog__header{
    padding:0px !important
  }
  .el-dialog__body{
    padding:0px !important
  }
  box-shadow: 0px 0px 0px 0px !important;
  .el-loading-mask{
    border-radius: 10px;
  }
}

.da-close{
  position: absolute;
  top: 14px;
  right: 14px;
}
.da-deail{
  position: relative;
  bottom: 176px;
}
</style>
