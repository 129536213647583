<template>
  <el-button
    v-inmodule="inmodule"
    :type="type"
    class="TextButton pointer h-auto lh--auto"
    :class="classs"
    link
    plain
    v-bind="$attrs"
    :disabled="disabled"
    @click="toRouter(to)"
  >
    <slot />
  </el-button>
</template>

<script setup>

const route = useRoute()

const props = defineProps({
  type: {
    default: ''
  },
  to: {
    default: '/'
  },
  disabled: {
    default: false
  }
})

const inmodule = $computed(() => {
  const re = /\//
  const path = re.test(props.to) ? props.to : `/${props.to}`
  return path
})

const classs = computed(() => {
  const list = [`c-${props.type}`]

  return list
})

const toRouter = path => {
  if (route.path === path) {
    return
  }
  router.push(path)
}
</script>

<style lang="scss">
.TextButton {
  padding: 0px !important;
  cursor: pointer;
}
.TextButton + .TextButton {
  margin-left: 0px;
}
</style>

