
/**
 * [
//   [
//     1,2,3,4,5,6,7,8,9,10,11 to
//   ]
// ]
[
//   {
//     p:1,
//     time:2,
//     ct:3,
//     open:4,
//     close:5,
//     high:6,
//     low:7,
//     volume:8,
//     c:9,
//     pc:10,
//     pcp:11
//   }
// ]
// [
//   {
//     time:2,
//     open:4,
//     close:5,
//     high:6,
//     low:7,
//     volume:8,
//   }
// ]
 * @param {*} arr 原数组
 * @param {*} matchKeys 第一次匹配的全数组
 * @param {*} tvFields 第二次匹配的精准数组
 * @returns
 */
export const mapArrayToGetBarsData = (arr, matchKeys, tvFields) => {
  const objArr = []
  const matchKeysLength = matchKeys.length
  const tvFieldsLength = tvFields.length

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    const obj = {}

    for (let j = 0; j < matchKeysLength; j++) {
      obj[matchKeys[j]] = item[j]
    }

    objArr.push(obj)
  }

  const filteredArr = []

  for (let i = 0; i < objArr.length; i++) {
    const obj = objArr[i]
    const newObj = {}

    for (let j = 0; j < tvFieldsLength; j++) {
      const field = tvFields[j]
      newObj[field] = obj[field]
    }

    filteredArr.push(newObj)
  }

  return filteredArr
}

