
import axios from '@/utils/request'



// 申请记录
const userListApplication = '/activity/user/listingApplication'
const userList = async (params) => {
  const { data } = await axios.post(userListApplication, params)
  return data.data
}

// 返佣记录/邀请记录
const savelistingApplicationUrl = '/activity/save/listingApplication'
const saveListingForm = async (params = {}) => {
  const { data } = await axios.post(savelistingApplicationUrl, params)
  return data.data
}

// 赛道列表 
const businessAreaOptionUrl = '/activity/businessAreaList'
const businessAreaOption = async (params) => {
  const { data } = await axios.post(businessAreaOptionUrl, params)
  return data.data
}


const needLoginUrl = []

export default {
  needLoginUrl,
  userList,
  saveListingForm,
  businessAreaOption,
}
