<!--
 * @Author: ken yang
 * @Date: 2023-03-06 19:00:53
 * @LastEditTime: 2023-03-16 13:42:23
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/components/widget/HideNum.vue
-->
<template>
  <span v-if="isOpenEye" test-key="hide-num">
    <slot />
  </span>
  <span v-else test-key="hide-num">******</span>
</template>
<script setup>
const userStore = useUserStore()
const isOpenEye = $computed(() => {
  return userStore?.isOpenEye
})
</script>
<style lang="scss">

</style>
