
const useWinMesSetSize = () => {
  const hasHeight = ref(false)

  onMounted(() => {
    globalThis.winId && runTimer(() => {
      if (document.body.offsetHeight && !hasHeight.value) {
        winMes.postParent('bodyInfo', {
          height: document.body.offsetHeight
        })
        hasHeight.value = true
      }
      return !hasHeight.value
    }, 350)
  })
}

export { useWinMesSetSize }
