/*
 * @Author: ken yang
 * @Date: 2022-09-28 20:13:54
 * @LastEditTime: 2023-07-27 14:06:07
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/user.js
 */
import axios from '@/utils/request'

const loginUrl = '/user/login'
const login = async (body) => {
  const { data } = await axios.post(loginUrl, body, { auth: false })
  return data.data
}

const loginByCodeUrl = '/user/loginByCode'
const loginByCode = async (body) => {
  const { data } = await axios.post(loginByCodeUrl, body)
  return data.data
}

const areaListUrl = '/user/query/country-code'
const areaList = async (body = {}) => {
  const { data } = await axios.post(areaListUrl, body, { auth: false })
  return data.data
}

const registeredUrl = '/user/registered'
const registered = async (body) => {
  const { data } = await axios.post(registeredUrl, body)
  return data.data
}

const forgotPasswordUrl = '/user/update/login-password'
const forgotPassword = async (body) => {
  const { data } = await axios.post(forgotPasswordUrl, {
    type: 1,
    ...body,
  })
  return data.data
}

const infoUrl = '/user/info'
const info = async (body) => {
  const { data } = await axios.post(infoUrl, body)
  return data.data
}

const feeUrl = '/order/get-exchange-fee-level'
const fee = async (body) => {
  const { data } = await axios.post(feeUrl, body)
  return data.data
}

const accountInfoUrl = '/account/query/info/simple'
const accountInfo = async (body = {}) => {
  const { data } = await axios.post(accountInfoUrl, body)
  const res = data.data
  res?.coinAccountList?.forEach(obj => {
    obj.convertUsdtAmount = +obj.convertUsdtAmount
  })
  return res
}

const updateInfoUrl = '/user/update/info'
const updateInfo = async (body) => {
  const { data } = await axios.post(updateInfoUrl, body)
  return data
}

const AnnouncementsWindowsUrl = '/user/announcements/windows'
const AnnouncementsWindows = async (body) => {
  const { data } = await axios.post(AnnouncementsWindowsUrl, body, { auth: false })
  return data.data
}

const qrOcdeLoginUrl = '/user/qrcode/getCode'
const qrcodeGetCode = async (body = {}) => {
  const { data } = await axios.post(qrOcdeLoginUrl, body, { auth: false })
  return data
}

const qrOcdeStatusUrl = '/user/qrcode/codeStatus'
const qrOcdeStatus = async (body = {}) => {
  const { data } = await axios.post(qrOcdeStatusUrl, body)
  return data.data
}

const oauthUrl = '/user/google/oauthUrl'
const getOauthUrl = async (body = {}) => {
  const { data } = await axios.post(oauthUrl, body)
  return data.data
}

const expiredCodeUrl = '/user/qrcode/expiredCode'
const expiredCode = async (body = {}) => {
  const { data } = await axios.post(expiredCodeUrl, body)
  return data.data
}

const callbackGoogleUrl = '/user/callback/google'
const callbackGoogle = async (body = {}) => {
  const { data } = await axios.post(callbackGoogleUrl, body)
  return data.data
}

const billboardUrl = '/user/billboard'
const billboard = async (body = {}) => {
  const { data } = await axios.post(billboardUrl, body)
  return data
}

const inviteCodeUrl = '/user/google/inviteCode'
const inviteCode = async (body = {}) => {
  const { data } = await axios.post(inviteCodeUrl, body)
  return data
}

const updateContactUrl = '/user/merchant/updateContact'
const updateContact = async (body = {}) => {
  const { data } = await axios.post(updateContactUrl, body)
  return data
}

const feeLevelDetailUrl = '/order/get-exchange-fee-level-detail2'
const feeLevelDetail = async (body = {}) => {
  const { data } = await axios.post(feeLevelDetailUrl, body)
  return data.data
}
const switchLanguageUrl = '/user/switchLanguage'
const switchLanguage = async (body = {}) => {
  const { data } = await axios.post(switchLanguageUrl, body)
  return data.data
}

const getIPLimitUrl = '/user/getIPLimit'
const getIPLimit = async (body = {}) => {
  const { data } = await axios.post(getIPLimitUrl, body)
  return data.data
}

const needLoginUrl = [accountInfoUrl]

export default {
  areaList,
  login,
  loginByCode,
  registered,
  forgotPassword,
  info,
  fee,
  accountInfo,
  updateInfo,
  AnnouncementsWindows,
  qrcodeGetCode,
  qrOcdeStatus,
  getOauthUrl,
  expiredCode,
  callbackGoogle,
  billboard,
  inviteCode,
  updateContact,
  feeLevelDetail,
  switchLanguage,
  needLoginUrl,
  getIPLimit,
}
