/*
 * @Author: ken yang
 * @Date: 2022-09-03 11:18:27
 * @LastEditTime: 2023-02-28 16:57:01
 * @LastEditors: ken yang
 * @Description: c2c 路由文件
 * @FilePath: /uexch_pc/src/view/ieo/index.js
 */
import AppLayout from '@/layout/AppLayout.vue'

// import Quotes from '@/view/quotes/Quotes.vue'
const notice = () => import('@/view/notice/notice.vue')
const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/notice',
        component: notice,
        meta: {
          key: 'notice',
        },
      },
      {
        path: '/notice/:type/:id',
        component: notice,
        meta: {
          key: 'notice',
        },
      },
    ],
  },
]
const components = {}

export default {
  routes,
  components,
}
