const runTimer = (b, t = 1000, p = false) => {
  let n
  if (p == true) {
    n = b()
  }
  setTimeout(() => {
    if (p == false) {
      n = b()
    }
    if (n) {
      runTimer(b, t)
    }
  }, t)
}

export { runTimer }

