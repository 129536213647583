/*
 * @Author: ken yang
 * @Date: 2022-09-28 19:44:55
 * @LastEditTime: 2023-09-22 10:53:14
 * @LastEditors: ken yang
 * @Description: 钱包路由
 * @FilePath: /uexch_pc/src/view/wallet/index.js
 */
const AppLayout = () => import('@/layout/AppLayout.vue')
const WalletLayout = () => import('@/view/wallet/layout/WalletLayout.vue')
const WalletOverview = () => import('@/view/wallet/walletOverview/WalletOverview.vue')
const ProfitLossAnalysis = () => import('@/view/wallet/profitLossAnalysis/ProfitLossAnalysis.vue')
const FiatAccount = () => import('@/view/wallet/fiatAccount/FiatAccount.vue')
const FinancialAccount = () => import('@/view/wallet/financialAccount/FinancialAccount.vue')
const Futures = () => import('@/view/wallet/futures/Futures.vue')
const WalletHistory = () => import('@/view/wallet/walletHistory/WalletHistory.vue')
const SpotAccount = () => import('@/view/wallet/spotAccount/SpotAccount.vue')
const WithdrawOut = () => import('@/view/wallet/components/page/WithdrawOut.vue')
const WithdrawInner = () => import('@/view/wallet/components/page/WithdrawInner.vue')
const Recharge = () => import('@/view/wallet/components/page/Recharge.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/wallet',
        component: WalletLayout,
        children: [
          {
            path: '/wallet/walletOverview',
            name: 'WalletOverview',
            title: 'wallet.assetsOverview',
            isMenu: true,
            component: WalletOverview,
          },
          // {
          //   path: '/wallet/profitLossAnalysis',
          //   name: 'ProfitLossAnalysis',
          //   title: 'wallet.profitLossAnalysis',
          //   isMenu: true,
          //   component: ProfitLossAnalysis,
          // },
          {
            path: '/wallet/spotAccount',
            name: 'SpotAccount',
            title: 'wallet.spotAccount',
            isMenu: true,
            component: SpotAccount,
          },
          {
            path: '/wallet/futures',
            name: 'Futures',
            title: 'wallet.futures',
            isMenu: true,
            component: Futures,
          },
          {
            path: '/wallet/financialAccount',
            name: 'FinancialAccount',
            title: 'wallet.financialAccount',
            isMenu: true,
            component: FinancialAccount,
          },
          {
            path: '/wallet/fiatAccount',
            name: 'FiatAccount',
            title: 'wallet.fiatAccount',
            isMenu: true,
            component: FiatAccount,
          },
          {
            path: '/wallet/walletHistory',
            name: 'WalletHistory',
            title: 'wallet.assetsHistory',
            isMenu: true,
            component: WalletHistory,
          },
        ]
      },
      {
        path: '/wallet/withdrawOut',
        name: 'WithdrawOut',
        component: WithdrawOut,
      },
      {
        path: '/wallet/withdrawInner',
        name: 'WithdrawInner',
        component: WithdrawInner,
      },
      {
        path: '/wallet/recharge',
        name: 'Recharge',
        component: Recharge,
      }
    ],
  },
]
const components = {}
export default {
  routes,
  components,
}

