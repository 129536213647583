/*
 * @Author: ken yang
 * @Date: 2023-08-30 17:28:53
 * @LastEditTime: 2023-08-30 17:42:30
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/verifyCode.js
 */
import axios from '@/utils/request'

const loginCodeUrl = '/user/getPhoneOrEmailCode'
const loginCode = async (body) => {
  const { data } = await axios.post(loginCodeUrl, body)
  return data.data
}

const registerCodeUrl = '/user/registered/send/verification-code'
const registerCode = async (body) => {
  const { data } = await axios.post(registerCodeUrl, {
    ...body,
  })
  return data.data
}

const phoneOrEmailUpdateUrl = '/user/phone-or-email/code'
const phoneOrEmailUpdate = async (body) => {
  const { data } = await axios.post(phoneOrEmailUpdateUrl, {
    ...body,
  })
  return data.data
}

const forgotPasswordCodeUrl = '/user/find/Password/send/verification-code'
const forgotPasswordCode = async (body) => {
  const { data } = await axios.post(forgotPasswordCodeUrl, {
    ...body,
  })
  return data.data
}

const checkCorgotPasswordCodeUrl = '/user/check/verification-code'
const checkCorgotPasswordCode = async (body) => {
  const { data } = await axios.post(checkCorgotPasswordCodeUrl, {
    ...body,
  })
  return data.data
}
// 以后发送验证码统一使用这个接口
const sendCorgotPasswordCodeUrl = '/user/send/verification-code'
const sendCorgotPasswordCode = async (body) => {
  const { data } = await axios.post(sendCorgotPasswordCodeUrl, {
    ...body,
  })
  return data
}
// 判断是否需要图形验证码验证接口
const kaptchaIsNeedUrl = '/user/kaptcha/is-need'
const kaptchaIsNeed = async (body = {}) => {
  const { data } = await axios.post(kaptchaIsNeedUrl, body)
  return data.data
}
// 提前验证手机号码/邮箱是否正确接口
const accountIsRightUrl = '/user/phone-email/is-right'
const accountIsRight = async (body = {}) => {
  const { data } = await axios.post(accountIsRightUrl, body)
  return data
}

const needLoginUrl = []

export default {
  loginCode,
  registerCode,
  phoneOrEmailUpdate,
  forgotPasswordCode,
  checkCorgotPasswordCode,
  sendCorgotPasswordCode,
  kaptchaIsNeed,
  accountIsRight,
  needLoginUrl,
}
