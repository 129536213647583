/*
 * @Author: ken yang
 * @Date: 2022-10-08 14:06:46
 * @LastEditTime: 2023-03-17 13:42:07
 * @LastEditors: ken yang
 * @Description: 活动相关接口
 * @FilePath: /uexch_pc/src/api/activity.js
 */
import axios from '@/utils/request'

// 当前活动的信息
const currentActivityInfoUrl = '/activity/currentActivityInfo'
const currentActivityInfo = async (params) => {
  const { data } = await axios.post(currentActivityInfoUrl, params)
  return data.data
}

// 当前用户的信息
const userCurrentUrl = '/activity/user/currentActivityInfo'
const userCurrent = async (params) => {
  const { data } = await axios.post(userCurrentUrl, params)
  return data.data
}

// 用户提交截图
const userAuditUrl = '/activity/user/audit'
const userAudit = async (params) => {
  const { data } = await axios.post(userAuditUrl, params)
  return data.data
}

//  用户领奖
const receiveAwardUrl = '/activity/user/receiveAward'
const receiveAward = async (params) => {
  const { data } = await axios.post(receiveAwardUrl, params)
  return data.data
}

// 弹窗list
const checkPopUlr = '/activity/reward/check-pop'
const checkPop = async (params = {}) => {
  const { data } = await axios.post(checkPopUlr, params)
  return data.data
}

// 手动领取奖励
const forUserUrl = '/activity/reward/forUser'
const forUser = async (params = {}) => {
  const { data } = await axios.post(forUserUrl, params)
  return data.data
}

// 新人拉新活动
const popUpOrNotUrl = '/activity/newRegisterActivity/novice/rank/popUpOrNot'
const popUpOrNot = async (params = {}) => {
  const { data } = await axios.post(popUpOrNotUrl, params)
  return data.data
}

// 拉新活动领取奖励
const newRegisterActivitySubmitUrl = '/activity/user/newRegisterActivity/submit'
const newRegisterActivitySubmit = async (params = {}) => {
  const { data } = await axios.post(newRegisterActivitySubmitUrl, params)
  return data
}

const needLoginUrl = [userCurrentUrl, userAuditUrl, receiveAwardUrl, popUpOrNotUrl]

export default {
  needLoginUrl,
  currentActivityInfo,
  userCurrent,
  userAudit,
  receiveAward,
  checkPop,
  forUser,
  popUpOrNot,
  newRegisterActivitySubmit
}
