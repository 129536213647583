<!--
 * @Author: ken yang
 * @Date: 2022-08-30 11:14:13
 * @LastEditTime: 2023-08-28 14:59:34
 * @LastEditors: ken yang
 * @Description: 布局入口
 * @FilePath: /uexch_pc/src/layout/AppLayout.vue
-->
<template>
  <Column class="AppLayout" :class="pageThemeClass">
    <AppHeader v-if="!route.query.winId && !headerHide" class="w-100b" :class="headerFixed ? 'header-fixed' : ''" />
    <div v-if="headerFixed && !headerHide && !headerTransparent" :headerHide="headerHide"
      :headerTransparent="headerTransparent" class="h-66" />
    <router-view v-slot="{ Component }">
      <Column class="w-100b app-body">
        <!-- <Announcement /> -->
        <EntryDiaManage />
        <Component :is="Component" :class="className" />
      </Column>
    </router-view>
    <AppFooter v-if="!footerHide" class="w-100b" />

  </Column>
</template>

<script setup>
const route = useRoute()
const props = defineProps({
  price: {},
  amount: {},
  total: {},
  percent: {},
  type: {}
})
const configStore = useConfigStore()

// registerUpLand
const footerHidePage = ['/registerUpLand', '/trade', '/futures']
const headerHidePage = ['/registerUpLand']
const headerTransparentPage = []
const footerHide = $computed(() => {
  return footerHidePage.includes(route.path)
})
const headerHide = $computed(() => {
  return headerHidePage.includes(route.path)
})

const headerTransparent = $computed(() => {
  return headerTransparentPage.includes(route.path) || route.path.includes('/notice')
})

const className = $computed(() => {
  if (route.query.winId) {
    return ['componentIframe', 'w-100b', 'bg-d']
  } else if (headerHide == true && footerHide == true) {
    return ['hideHF', 'w-100b', 'bg-d']
  } else {
    return ['component', 'w-100b', 'bg-d']
  }
})
const pageThemeClass = $computed(() => {
  if (['/trade', '/ffutures', '/futures'].includes(route.path)) {
    return `${configStore.theme} `
  } else {
    return ''
  }
})

const headerFixed = $computed(() => {
  return true
})

onBeforeMount(() => { })
</script>

<style lang="scss">
.AppLayout {
  width: 100%;

  .header-fixed {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    opacity: 0.8;
  }

  .component {
    min-height: calc(100vh - 420px);
  }

  .componentIframe {
    min-height: calc(100vh - 350px);
  }

  .hideHF {
    min-height: 100vh;
  }
}
</style>
