import { randomString } from './random'
import hexToRgba from './hexToRgba'
import { runTimer } from './timer'
import throttle from './throttle'
import debounce from './debounce'
import copyToClip from './copyToClip'
import { inModule, idleInModule, idleInModuleList, } from './inModule'
import { jsonStrToValue } from './json'
import getUrlQuery from './getUrlQuery'
import fixTime from './fixTime'
import downloadImage from './downloadImage'
import lang from './lang'
import setTimeRange from './setTimeRange'
import reserveUrlQuery from './reserveUrlQuery'
import replaceParamVal from './replaceParamVal'
import downloadExcel from './downloadExcel.js'
import borwserLang from './borwserLang.js'
import cookies from './cookies.js'
import { getDecimalPlaces, c2cDecimalPlaces } from './getDecimalPlaces.js'
import { setLocalStorageWithExpiration, getLocalStorageWithExpiration } from './localStorage.js'
import { floorInt, roundup, ceilingInt } from './mathInt.js'
import assignObj from './assignObj.js'

import {
  checkType,
  isArray,
  isString,
  isNumber,
  isObject,
  isBoolean,
  isFunc,
  isRe,
  isJsonString,
  isEmpty,
  hasEmpty,
  isNaN,
  isZero,
} from './verifyType'

import { gt, gte, lt, lte } from './compare'

const fieldSet = (obj, key, value = null) => {
  if (isEmpty(obj[key])) {
    return
  }
  obj[key] = value
}

const addZero = (n) => {
  const s = String(n)
  if (s.length != 2) {
    return `0${s}`
  } else {
    return s
  }
}

const hideAccount = (str) => {
  return `${str.slice(0, 3)}****${str.slice(str.length - 4, str.length)}`
}

const toRouter = (params, p = {}) => {
  const { requireLogin = false, redirect, addUrlQuery = true } = p

  if (requireLogin) {
    const userStore = useUserStore()
    if (!userStore.token) {
      router.push('/login')
      router.push({
        path: '/login',
        query: { redirect }
      })
      return
    }
  }
  if (isObject(params)) {
    if (!params.path) {
      throw new Error('参数错误')
    }
    var query = { ...params.query || {}}
    if (addUrlQuery) {
      query = {
        ...$route.query,
        ...query,
      }
    }
    router.push({
      path: params.path,
      query
    })
  } else if (typeof params === 'string') {
    router.push(params)
  } else {
    throw new Error('参数错误')
  }
}

const scrollbottom = (dom) => {
  const scrollTop = dom.scrollTop
  const clientHeight = dom.clientHeight
  const scrollHeight = dom.scrollHeight
  if (scrollTop + clientHeight >= scrollHeight - 10) {
    return true
  } else {
    return false
  }
}

const delNullKey = data => {
  return Object.keys(data).reduce((o, k) => {
    const e = {}
    const item = data[k]

    if (!isEmpty(item)) {
      e[k] = item
    }
    if (isObject(item)) {
      e[k] = delNullKey(item)
    }
    return { ...o, ...e }
  }, {})
}

const tryCatch = async (cb) => {
  try {
    return [null, await cb()]
  } catch (err) {
    return [err, null]
  }
}

export {
  checkType,
  isArray,
  isString,
  isNumber,
  isObject,
  isBoolean,
  isFunc,
  isRe,
  isJsonString,
  isEmpty,
  isNaN,
  hasEmpty,
  randomString,
  hexToRgba,
  runTimer,
  throttle,
  debounce,
  copyToClip,
  fieldSet,
  inModule,
  idleInModule,
  idleInModuleList,
  toRouter,
  scrollbottom,
  jsonStrToValue,
  delNullKey,
  fixTime,
  getUrlQuery,
  tryCatch,
  downloadImage,
  lang,
  setTimeRange,
  reserveUrlQuery,
  replaceParamVal,
  downloadExcel,
  borwserLang,
  addZero,
  isZero,
  gt,
  gte,
  lt,
  lte,
  getDecimalPlaces,
  cookies,
  c2cDecimalPlaces,
  hideAccount,
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
  floorInt,
  roundup,
  ceilingInt,
  assignObj
}
