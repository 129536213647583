const jsonStrToValue = (str) => {
  const map = {
    'true': true,
    'false': false,
    'null': null,
    'undefined': undefined,
  }
  return map[str]
}

export { jsonStrToValue }
